<script lang="ts"></script>

<script setup lang="tsx">
import { ConfirmationService, DialogService, ToastService, useDialogRef } from '@/utils/primevue';
import dialogContent from './dialog-content.vue';

const dynamicComponent = defineComponent({
  props: {
    'defining-props': {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const dialogRef = useDialogRef();
    return () => (
      <div>
        <button onClick={() => ctx.emit('close')}>emit('close')</button> <hr />
        <pre>{JSON.stringify({ 'dialogRef?.data': dialogRef!.value.data }, null, 2)}</pre> <hr />
        <div>PrimeVue DynamicDialog</div> <hr />
        <pre>{`${JSON.stringify({ 'ctx.attrs': ctx, props }, null, 2)}`}</pre> <hr />
        <pre>{JSON.stringify({ "inject('dialogRef')": dialogRef!.value }, null, 2)}</pre> <hr />
      </div>
    );
  },
});

// https://primevue.org/dynamicdialog/
const openDialog = async () => {
  const dialog1 = DialogService.open(dynamicComponent, {
    props: {
      header: '对话框1 可以拖动',
      position: 'bottomleft',
      draggable: true,
      pt: { mask: { class: 'backdrop-blur-sm' } }, // 相当于: pt:mask:class="backdrop-blur-sm"
    },
    data: {
      用inject接收: '通过 DynamicDialogOptions.data 传递的数据',
    },
    emits: {
      // https://github.com/primefaces/primevue/blob/bd7161298a472c8cd954e35e6a538a8bd1b1b386/packages/primevue/src/dynamicdialog/DynamicDialog.vue#L5
      //   ↑v-bind="instance.options.emits", 所以 props 也可以通过 emits 传递给 content 的组件
      'defining-props': {
        '用-props-接收': '定义在-DynamicDialogOptions.emits-的数据',
      },
      绑定给组件的attrs: '定义在-DynamicDialogOptions.emits-的数据，',
      onClose: () => dialog1.close(),
    },
  });

  await nextTick();
  // if ($__DEV__) return;

  await new Promise((resolve) => setTimeout(resolve, 300));
  DialogService.open(dialogContent, {
    props: {
      header: '对话框2',
      // draggable: false, // Header1的 draggable: true 会影响 Header2，如果指定 draggable: false，则不会受到影响。
    },
  });
};
const openToast = () => {
  ToastService.add({ severity: 'info', summary: '提示', detail: '消息内容', life: 3000 });
};
const openConfirm = async () => {
  ConfirmationService.require({
    message: '确定要继续吗？',
    header: '确认',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: { label: '取消', severity: 'secondary', outlined: true },
    acceptProps: { label: '确定' },
    accept: () => {
      ToastService.add({ severity: 'info', summary: '已确认', detail: '您已同意操作', life: 3000 });
    },
    reject: () => {
      ToastService.add({ severity: 'error', summary: '已取消', detail: '您已取消操作', life: 3000 });
    },
  });
};
</script>

<template>
  <div class="primevue">
    <InputText placeholder="请输入" />
    <Select
      :options="[
        { name: '纽约', code: 'NY' },
        { name: '罗马', code: 'RM' },
        { name: '伦敦', code: 'LDN' },
        { name: '伊斯坦布尔', code: 'IST' },
        { name: '巴黎', code: 'PRS' },
      ]"
      optionLabel="name"
      placeholder="选择城市"
      class="min-w-[200px]"
    />

    <DatePicker dateFormat="dd/mm/yy" />

    <Button @click="openToast">提示服务</Button>
    <Button @click="openDialog">对话框服务</Button>
    <Button @click="openConfirm">确认服务</Button>
  </div>
</template>
